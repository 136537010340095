import Component from 'vue-class-component';
import { siteService, homeOwnerService, meterReadingService } from '@/main';
import { BModal } from 'bootstrap-vue';
import to from 'await-to-js';
import { AxiosResponse } from 'axios';
import PlaceType from '../../models/PlaceType';
import Place from '../../models/Place';
import BasePage from '../../models/BasePage';
import moment from 'moment/min/moment.min.js';
import { Watch } from 'vue-property-decorator';
import HomeOwner from '../../models/HomeOwner';
import { IVuelidate } from 'vuelidate';
import { $router } from '@/router';
import Vue from 'vue';
import { sitesModule } from '@/store/modules/site';
import PropertyType from '@/models/PropertyType';

@Component
export default class HomeOwnerAccomodation extends BasePage implements IVuelidate<any> {
    public placeKey: string = '';
    public place: Place = {} as Place;
    public homeOwners: HomeOwner[] = [] as HomeOwner[];
    public selectedHomeOwner: HomeOwner = null;
    public meterReadingData: any[] = [];
    public newMeterData: any = {
        ean: '',
        meterType: '',
        mutationDate: new Date(),
        startValue: 0,
        oldValue: 0,
    };

    public $refs!: {
        homeOwnerModal: BModal;
        newMeterModal: BModal;
    };

    public selectedStatus = null;
    public statusses = [
        { id: 1, text: 'Beschikbaar', value: 'Available' },
        { id: 2, text: 'Deels', value: 'Partial' },
        { id: 3, text: 'Geblokkeerd', value: 'Blocked' },
    ];

    public meterTypes = [
        { id: 1, text: 'Elektriciteit', value: 'Energy' },
        { id: 2, text: 'Gas', value: 'Gas' },
        { id: 3, text: 'Water', value: 'Water' },
    ];

    public plotTypes = [
        { id: 'Default', name: 'Niet gedefinieerd' },
        { id: 'Ownership', name: 'Koop' },
        { id: 'Rental', name: 'Huur' },
    ];

    public selectedPlotType = { id: 'Default', name: 'Niet gedefinieerd' };

    @Watch('selectedHomeOwner', { deep: true, immediate: true })
    public OnHomeOWnerChange(val, oldVal) {
        if (val) {
            this.place.homeOwnerId = val.homeOwnerId;
        }
    }

    public async created() {
        const self = this;

        this.placeKey = this.$route.params.accoKey;
        this.place = await this.loadAccommodation(this.placeKey);

        this.selectedStatus = this.statusses.find((x) => x.value === this.place.rentalState);

        if (this.place.siteId !== sitesModule.activeSite.siteId) {
            await this.$router.push({ name: 'home-owner-accos' });
        }

        await Promise.all([this.initSite(), this.getHomeOwners(), this.loadMeterReading(this.place.placeId)]);

        if (this.place.homeOwnerId) {
            this.selectedHomeOwner = this.homeOwners.find((x) => x.homeOwnerId === self.place.homeOwnerId);
        }

        if (this.place.plotType) {
            this.selectedPlotType = this.plotTypes.find((x) => x.id === this.place.plotType);
        }

        this.isLoading = false;
    }

    public async loadAccommodation(placeKey) {
        let place = new Place();
        if (placeKey !== 'aanmaken') {
            const [placeErr, placeResponse] = await to<AxiosResponse<Place>>(homeOwnerService.getAccommodation(placeKey));
            if (placeErr) {
                this.showFailedResponse('Mislukt om park gegevens op te halen', placeErr);
                return {} as Place;
            }
            place = new Place(placeResponse.data);
        }

        place.propertyType = place.propertyType || new PropertyType();
        place.images = place.images || [];
        place.videos = place.videos || [];
        place.features = place.features || [];
        return place;
    }

    public goToCreateCommisionInvoice() {
        this.$router.push({
            name: 'invoice-create',
            query: { homeOwnerId: this.place.homeOwnerId.toString(), placeId: this.place.placeId.toString() },
        });
    }

    public getStatusLabel() {
        return this.statusses.find((x) => x.value === this.place.rentalState).text;
    }

    public showStatusChangeModal() {
        this.$refs.homeOwnerModal.show();
    }

    public editAnnualInvoiceModelSideAction() {
        this.$sideActions.push('create-or-edit-annual-invoice-model-side-action', { annualInvoiceId: this.place.annualInvoiceId }, (result) => {
            this.setAnnualInvoice(result);
        });
    }

    public async savePlace() {
        this.showPending('Accommodatie opslaan...');

        this.place.rentalState = this.selectedStatus.value;
        this.place.plotType = this.selectedPlotType.id;

        const [err, response] = await to(siteService.updateHomeOwnerPlace(this.place));
        if (err) {
            return this.clearAndShowError('Mislukt om de accommodatie op te slaan', err);
        }

        this.editMode = false;
        this.$refs.homeOwnerModal.hide();
        this.clearAndShowSuccess('Accommodatie succesvol opgeslagen.');
    }

    public showChangeGWEmeter() {
        this.$refs.newMeterModal.show();
    }

    public async addMeter() {
        this.showPending('Nieuwe meter toevoegen...');

        const mutationDate = moment(this.newMeterData.mutationDate)
            .add(12, 'h')
            .toDate();

        const updateRequest = {
            mutationDate,
            energy: this.newMeterData.oldValue,
            gas: this.newMeterData.oldValue,
            water: this.newMeterData.oldValue,
        };

        if (this.newMeterData.meterType !== 'Energy') {
            delete updateRequest.energy;
        }

        if (this.newMeterData.meterType !== 'Gas') {
            delete updateRequest.gas;
        }

        if (this.newMeterData.meterType !== 'Water') {
            delete updateRequest.water;
        }

        await meterReadingService.saveMeterReadings(updateRequest, this.place.placeId);

        await meterReadingService.addNewMeter(this.place.placeId, {
            ean: this.newMeterData.ean,
            mutationDate,
            startValue: this.newMeterData.startValue,
            oldValue: this.newMeterData.oldValue,
            meterType: this.newMeterData.meterType.value,
        });

        this.clearAndShowSuccess('Nieuwe meter toegevoegd');
        this.$refs.newMeterModal.hide();
    }

    public getNameHomeOwnerName(homeOwner: HomeOwner) {
        return `${homeOwner.firstName}${homeOwner.insertion ? ' ' + homeOwner.insertion : ''} ${homeOwner.lastName}`;
    }

    public getHomeOwner(id: number) {
        if (id) {
            return this.getNameHomeOwnerName(this.homeOwners.find((homeOwner) => homeOwner.homeOwnerId === id));
        } else {
            return 'Het park is eigenaar van dit object';
        }
    }

    public hasAllMeters() {
        return this.meterReadingData.length === 3;
    }

    public renderInvoiceLink(h: any, a, row) {
        const route = $router.resolve({
            name: 'invoice',
            params: { siteId: this.siteId.toString(), siteKey: this.siteKey, invoiceId: row.dataItem.invoiceId },
        });

        const props = { text: row.dataItem.invoiceNumber, url: route.href };

        return h(Vue.component('grid-router-link'), { props });
    }

    private setAnnualInvoice(annualInvoice) {
        if (!this.place.annualInvoiceId) {
            this.place.annualInvoiceId = annualInvoice.annualInvoiceId;
        }
    }

    private async getHomeOwners() {
        const [err, response] = await to(homeOwnerService.getHomeOwners());
        if (err) {
            return this.showError('Mislukt om eigenaren op te halen');
        }

        return (this.homeOwners = response.data);
    }

    private async loadMeterReading(placeId) {
        const [err, response] = await to(meterReadingService.getMeterReading(placeId));
        if (err) {
            this.clearAndShowError('Mislukt om de meterstanden op te halen', err);
            return null;
        }

        this.clearNotifications();

        return (this.meterReadingData = response.data);
    }
}
