import InvoiceLine from './InvoiceLine';
import Booking from './Booking';
import Booker from './Booker';

export class Invoice {
    public invoiceId: number = 0;
    public invoiceNumber: string;
    public bookingId: number;
    public mainBookerId: number;
    public homeOwnerId: number;
    public description: string = '';
    public invoiceDate: Date = new Date();
    public dueDate?: Date = new Date();
    public totalAmount: number = 0;
    public amountPaid: number = 0;
    public amountLeft: number = 0;
    public reference: string;
    public source: string = '';
    public status: number;
    public isDeleted: boolean;
    public isRegisterSale: boolean = false;
    public isMainBookingInvoice: boolean = false;
    public isCreditInvoice: boolean = false;

    public invoiceLines: InvoiceLine[] = [];

    public booking: Booking;
    public mainBooker: Booker;

    constructor(obj?: Partial<Invoice>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
