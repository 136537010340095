import Component from 'vue-class-component';
import BasePage from '@/models/BasePage';
import Place from '@/models/Place';
import Vue from 'vue';
import { siteService, homeOwnerService, meterReadingService } from '@/main';
import to from 'await-to-js';
import HomeOwner from '@/models/HomeOwner';
import { BModal } from 'bootstrap-vue';
import { $router } from '@/router';
import MeterReading, { MeterReadingItem } from '@/models/MeterReading';
import { Watch } from 'vue-property-decorator';
import { sitesModule } from '@/store/modules/site';
import moment from 'moment';
import { CompositeFilterDescriptor, filterBy, orderBy } from '@progress/kendo-data-query';
@Component
export default class AccoEditPage extends BasePage {
    public isLoading: boolean = true;
    public isLoadingMeter: boolean = true;
    public homeOwners: HomeOwner[] = [] as HomeOwner[];
    public currentPlace: Place = null;
    public selectedHomeOwner: HomeOwner = null;
    public indexPercentage: number = 0;
    public selectedProperty: string = 'selected';

    public mutationDate: Date = new Date();

    public meterReadings: MeterReading[] = [];
    public currentMeterReadings: MeterReading = new MeterReading();

    public generateInvoiceScope: boolean = false;

    public fromDisabledDate: Date = new Date();

    public places: Place[] = [];
    public allPlaces: Place[] = [];
    public statusses = [
        { id: 1, text: 'Beschikbaar', value: 'Available' },
        { id: 2, text: 'Deels', value: 'Partial' },
        { id: 3, text: 'Geblokkeerd', value: 'Blocked' },
    ];

    public placesGridColumns = [
        { filterable: false, field: 'selected', width: 60, locked: true, className: 'bg-white' },
        { filterable: false, field: 'lotNumber', title: 'Pleknummer', width: 120, editable: false, cell: this.renderLotNumber },
        { field: 'name', title: 'Naam', editable: false, cell: this.renderAccomodationName, filterCell: this.renderAccoTypeFilter },
        { field: 'plotType', title: 'Koop- of huurgrond', editable: false, cell: this.renderPlotType, filterCell: this.renderPlotTypeFilter, width: 200 },
        { field: 'rentalState', title: 'Beschikbaarheid', cell: (h, _, row) => h('td', row.dataItem.rentalStateText), filterCell: this.renderStatusFilter, width: 150 },
        { filterable: false, field: 'home-owner', title: 'Huiseigenaar', cell: this.renderHomeOwnerLink },
        { filterable: false, cell: this.renderActions, title: 'Actions', width: 150 },
    ];

    public filter: CompositeFilterDescriptor = {
        logic: 'and',
        filters: [],
    };

    public skip: number = 0;
    public take: number = 25;
    public sort: any[] = [{ field: 'lotNumber', dir: 'asc' }];

    public $refs!: {
        homeOwnerModal: BModal;
        indexModal: BModal;
        addMeterReading: BModal;
    };

    @Watch('currentPlace')
    public async OnSelectedPlaceChange(val, oldVal) {
        if ((val && !oldVal) || (val && oldVal && val.placeId !== oldVal.placeId)) {
            this.isLoadingMeter = true;
            const meterReadingData = await this.loadMeterReading(val.placeId);
            this.currentMeterReadings.items = [];

            if (meterReadingData && meterReadingData.length > 0) {
                if (meterReadingData && meterReadingData.length > 0) {
                    this.currentMeterReadings.items.push(...meterReadingData.map((x) => new MeterReadingItem(x)));
                }

                if (this.currentMeterReadings.items.length > 0) {
                    this.fromDisabledDate = moment(this.currentMeterReadings.items[0].latestMutationDate, 'YYYY-MM-DD').toDate();
                    this.mutationDate = moment(this.currentMeterReadings.items[0].latestMutationDate, 'YYYY-MM-DD').add(1, 'd').toDate();
                }
            }

            if (!this.currentMeterReadings.items.find((x) => x.meterType === 'Water')) {
                this.currentMeterReadings.items.push(
                    new MeterReadingItem({
                        meterType: 'Water',
                        isNew: true,
                    }),
                );
            }

            if (!this.currentMeterReadings.items.find((x) => x.meterType === 'Energy')) {
                this.currentMeterReadings.items.push(
                    new MeterReadingItem({
                        meterType: 'Energy',
                        isNew: true,
                    }),
                );
            }

            if (!this.currentMeterReadings.items.find((x) => x.meterType === 'Gas')) {
                this.currentMeterReadings.items.push(
                    new MeterReadingItem({
                        meterType: 'Gas',
                        isNew: true,
                    }),
                );
            }

            this.isLoadingMeter = false;
        }
    }

    public async loadMeterReading(placeId) {
        const [err, response] = await to(meterReadingService.getMeterReading(placeId));
        if (err) {
            this.clearAndShowError('Mislukt om de meterstanden op te halen', err);
            return null;
        }

        this.clearNotifications();

        return response.data;
    }

    public async mounted() {
        await Promise.all([this.getPlaces(), this.getPlaceTypes()]);
        this.isLoading = false;
    }

    public pageChangeHandler(event) {
        this.skip = event.page.skip;
        this.take = event.page.take;
    }

    public get total() {
        const filteredItems = filterBy(this.places, this.filter) as [];
        return filteredItems ? filteredItems.length : 0;
    }

    public placesFiltered(): any[] {
        const items = filterBy(this.places, this.filter).map((place: Place) => {
            const p: any = { ...place };
            const placeStatus = this.statusses.find((stat) => stat.value === p.rentalState);
            p.rentalStateText = placeStatus ? placeStatus.text : '';
            return p;
        });
        const ordered = orderBy(items, this.sort);
        return ordered.slice(this.skip, this.take + this.skip);
    }

    public allPlacesFiltered(): any[] {
        const items = filterBy(this.allPlaces, this.filter) as [];
        const ordered = orderBy(items, this.sort);
        return ordered;
    }

    public filterChange(ev) {
        this.filter = ev.filter;
    }

    public showIndexModal() {
        this.$refs.indexModal.show();
    }

    public cancel() {
        this.$refs.indexModal.hide();
    }

    public get AllEANsFilledIn() {
        return this.currentMeterReadings.items.filter((x) => !x.ean || x.ean.length === 0).length === 0;
    }

    public getMeterReadingObject(type) {
        if (this.currentMeterReadings && this.currentMeterReadings.items) {
            return this.currentMeterReadings.items.find((x) => x.meterType === type);
        }

        return null;
    }

    public async reloadPlaces() {
        this.places = (await homeOwnerService.getAccommodations(this.siteId)).data;
    }

    public async indexCosts() {
        let percentage = this.indexPercentage;
        this.showPending('Kosten indexeren...');

        if (this.indexPercentage.toString().indexOf(',') > -1) {
            percentage = parseFloat(this.indexPercentage.toString());
        }

        const [err, response] = await to(
            homeOwnerService.indexateAnnualInvoices(
                percentage,
                this.getSelectedPlaces().map((x) => x.placeId),
            ),
        );
        if (err) {
            return this.clearAndShowError('Mislukt om de kosten te indexeren', err);
        }
        await this.reloadPlaces();

        this.clearAndShowSuccess('Kosten geindexeerd');
        this.$refs.indexModal.hide();
    }

    public renderHomeOwnerLink(h: any, a, row) {
        const homeOwner = new HomeOwner(row.dataItem.homeOwner);

        if (!row.dataItem.homeOwner) {
            return h('td', ['-']);
        }

        const route = $router.resolve({
            name: 'home-owner',
            params: {
                siteId: row.dataItem.siteId.toString(),
                siteKey: sitesModule.sites.find((x) => x.siteId === row.dataItem.siteId).siteKey,
                homeOwnerId: row.dataItem.homeOwner.homeOwnerId,
            },
        });

        const title = homeOwner.name();
        const props = { text: title, url: route.href };

        return h(Vue.component('grid-router-link'), { props });
    }

    public renderActions(h, tdElement, row) {
        const actions = [
            { title: 'Koppel huiseigenaar', function: this.coupleHomeOwner },
            { title: 'Genereer GWE-jaarfactuur', function: this.generateYearInvoice },
            { title: 'Voeg meterstanden toe', function: this.showAddMeterReadingModalWithPlace },
        ];
        const props = { actions, item: row.dataItem };
        return h(Vue.component('grid-actions'), { props });
    }

    public async coupleHomeOwner(accommodation) {
        this.showPending('Alle eigenaren ophalen..');
        await this.getHomeOwners();

        this.clearNotifications();

        this.currentPlace = accommodation;
        this.selectedHomeOwner = this.homeOwners.find((owner) => owner.homeOwnerId === accommodation.homeOwnerId);
        this.$refs.homeOwnerModal.show();
    }

    public onHeaderSelectionChange(event) {
        const checked = event.event.target.checked;
        Vue.set(
            this,
            'places',
            this.places.map((item) => ({ ...item, selected: checked })),
        );
    }

    public getSelectedPlaces() {
        return this.places.filter((item) => item[this.selectedProperty] === true);
    }

    public onSelectionChange(event) {
        Vue.set(event.dataItem, this.selectedProperty, !event.dataItem[this.selectedProperty]);
    }

    public renderHomeOWner(h, tdElement, row) {
        const homeOwner = new HomeOwner(row.dataItem.homeOwner);

        if (!row.dataItem.homeOwner) {
            return h('td', ['-']);
        }

        return h('td', [homeOwner.name()]);
    }

    public renderCosts(h, _, row) {
        const costString = row.dataItem.rentCosts
            ? Vue.filter('formatPrice')(row.dataItem.rentCosts) + ' (huur)'
            : row.dataItem.serviceCosts
            ? Vue.filter('formatPrice')(row.dataItem.serviceCosts) + ' (service)'
            : 'N/A';
        return h('td', [costString]);
    }

    public async saveHomeOwner() {
        this.currentPlace.homeOwnerId = this.selectedHomeOwner.homeOwnerId;

        this.showPending('Eigenaar koppelen...');
        const [err, response] = await to(siteService.updateHomeOwnerPlace(this.currentPlace));
        if (err) {
            return this.clearAndShowError('Mislukt om de eigenaar te koppelen', err);
        }

        const place = this.places.find((placeArray) => placeArray.placeId === this.currentPlace.placeId);
        place.homeOwnerId = this.selectedHomeOwner.homeOwnerId;
        place.homeOwner = this.selectedHomeOwner;

        this.$refs.homeOwnerModal.hide();
        this.currentPlace = null;
        this.selectedHomeOwner = null;
        this.clearAndShowSuccess('Eigenaar succesvol gekoppeld.');
    }

    public getNameHomeOwnerName(homeOwner: HomeOwner) {
        return `${homeOwner.firstName}${homeOwner.insertion ? ' ' + homeOwner.insertion : ''} ${homeOwner.lastName}`;
    }

    public getDisabledDates() {
        return {
            start: null,
            end: this.fromDisabledDate,
        };
    }

    public renderAccomodationName(h, a, row) {
        const item = row.dataItem;
        const placeId = item.placeId ? item.placeId.toString() : '';
        const route = $router.resolve({
            name: 'home-owner-acco',
            params: { siteId: item.siteId.toString(), accoKey: placeId, siteKey: sitesModule.sites.find((x) => x.siteId === item.siteId).siteKey },
        });
        const props = { text: item.name, url: route.href };

        return h(Vue.component('grid-router-link'), { props });
    }

    public renderPlotType(h, a, row) {
        const item = row.dataItem;
        const placeId = item.placeId ? item.placeId.toString() : '';
        const route = $router.resolve({
            name: 'home-owner-acco',
            params: { siteId: item.siteId.toString(), accoKey: placeId, siteKey: sitesModule.sites.find((x) => x.siteId === item.siteId).siteKey },
        });

        let plotType = '-';
        if (item.plotType === 'Rental') {
            plotType = 'Huur';
        } else if (item.plotType === 'Ownership') {
            plotType = 'Koop';
        }

        const props = { text: plotType, url: route.href };

        return h(Vue.component('grid-router-link'), { props });
    }

    public renderLotNumber(h, a, row) {
        const item = row.dataItem;
        const placeId = item.placeId ? item.placeId.toString() : '';
        const route = $router.resolve({
            name: 'home-owner-acco',
            params: { siteId: item.siteId.toString(), accoKey: placeId, siteKey: sitesModule.sites.find((x) => x.siteId === item.siteId).siteKey },
        });
        const props = { text: item.lotNumber, url: route.href };

        return h(Vue.component('grid-router-link'), { props });
    }

    public async generateYearInvoice(place: Place) {
        this.$refs.addMeterReading.show();
        this.currentPlace = place;
        this.generateInvoiceScope = true;
    }

    public showAddMeterReadingModalWithPlace(place: Place) {
        this.generateInvoiceScope = false;
        this.currentPlace = place;
        this.$refs.addMeterReading.show();
    }

    public showAddMeterReadingModal() {
        this.$refs.addMeterReading.show();
    }

    public getPlaceNameAndLotNumber(place: Place) {
        return `${place.name} (${place.lotNumber})`;
    }

    public async saveMeterReadings() {
        const self = this;
        self.showPending('Waarden opslaan...');

        const mutationDate = moment(this.mutationDate).add(12, 'h').toDate();

        const newMeters = this.currentMeterReadings.items.filter((x) => x.isNew);
        if (newMeters.length > 0) {
            for (let i = 0; i < newMeters.length; i++) {
                const newMeterData = newMeters[i];

                await meterReadingService.addNewMeter(this.currentPlace.placeId, {
                    ean: newMeterData.ean,
                    mutationDate,
                    startValue: newMeterData.value,
                    meterType: newMeterData.meterType,
                });
            }
        }

        const updateRequest = {
            energy: this.currentMeterReadings.items.find((x) => x.meterType === 'Energy').value,
            gas: this.currentMeterReadings.items.find((x) => x.meterType === 'Gas').value,
            water: this.currentMeterReadings.items.find((x) => x.meterType === 'Water').value,
            mutationDate,
        };

        const [err] = await to(meterReadingService.saveMeterReadings(updateRequest, this.currentPlace.placeId));
        if (err) {
            self.clearAndShowError('Waarden opslaan mislukt', err);
        } else {
            self.clearAndShowSuccess('Waarden succesvol opgeslagen');
            this.$refs.addMeterReading.hide();
        }
    }

    public async generateGWEInvoice() {
        this.showPending('Factuur geneneren...');

        for (let i = 0; i < this.currentMeterReadings.items.length; i++) {
            const meterReading = this.currentMeterReadings.items[i];
            if (meterReading.value < meterReading.previousValue) {
                return this.clearAndShowWarning(`Eindstand (${meterReading.meterType}) kan niet kleiner zijn dan beginstand`);
            }

            meterReading.deposit = meterReading.numberOfDeposits * meterReading.depositAmount;
        }

        const mutationDate = moment(this.mutationDate).add(12, 'h').toDate();

        const model = {
            energy: this.currentMeterReadings.items.find((x) => x.meterType === 'Energy').value,
            depositEnergy: this.currentMeterReadings.items.find((x) => x.meterType === 'Energy').deposit,
            gas: this.currentMeterReadings.items.find((x) => x.meterType === 'Gas').value,
            depositGas: this.currentMeterReadings.items.find((x) => x.meterType === 'Gas').deposit,
            water: this.currentMeterReadings.items.find((x) => x.meterType === 'Water').value,
            depositWater: this.currentMeterReadings.items.find((x) => x.meterType === 'Water').deposit,
            mutationDate,
        };

        const [err] = await to(meterReadingService.createAnnualGWEInvoice(model, this.currentPlace.placeId, this.currentPlace.siteId));
        if (err) {
            this.clearAndShowError('Factuur genereren mislukt', err);
        } else {
            this.clearAndShowSuccess('Factuur genereren gelukt.');
            this.$refs.addMeterReading.hide();
        }
    }

    public update(dataItems) {
        this.places = dataItems;
    }

    private async getHomeOwners() {
        const [err, response] = await to(homeOwnerService.getHomeOwners());
        if (err) {
            return this.showError('Mislukt om eigenaren op te halen');
        }

        return (this.homeOwners = response.data.map((owner) => new HomeOwner(owner)));
    }

    private async getMeterReadings() {
        const [err, response] = await to(meterReadingService.getMeterReadings(this.siteId));
        if (err) {
            // return this.showError('Mislukt om meterstanden op te halen');
            return;
        }
        return (this.meterReadings = response.data);
    }

    private async getPlaces() {
        const [err, response] = await to(homeOwnerService.getAccommodations(this.siteId));
        if (err) {
            return this.showError('Mislukt om accommodaties op te halen');
        }
        return (this.allPlaces = this.places = response.data);
    }

    private async showAnnualCofirmModal() {
        const answer = await this.$bvModal.msgBoxConfirm(
            'LET OP! Weet je zeker dat je de jaarnotas wilt genereren en versturen? Deze verstuur je aan al de geselecteerde accommodaties!',
            {
                title: `Jaarnota's versturen`,
                okTitle: 'Ja',
                cancelTitle: 'Nee',
            },
        );

        if (answer) {
            await this.generateAnnualInvoices();
        }
    }

    private async generateAnnualInvoices() {
        this.showPending(`Jaarnota's genereren en versturen`);
        const [err, response] = await to(homeOwnerService.sendAnnualInvoices(this.getSelectedPlaces().map((x) => x.placeId)));
        if (err) {
            return this.clearAndShowError('Mislukt om accommodaties op te halen');
        }

        return this.clearAndShowSuccess(`Jaarnota's verstuurd`);
    }

    private renderAccoTypeFilter(h, a, row) {
        const props = {
            options: this.placeTypes.map((x) => {
                return { value: x.name, text: x.name };
            }),
            filter: this.filter,
            field: 'name',
            defaultSelected: [],
        };

        return h(Vue.component('grid-filter-multi'), { props });
    }

    private renderPlotTypeFilter(h, a, row) {
        const props = {
            options: [
                { value: 'Rental', text: 'Huur' },
                { value: 'Ownership', text: 'Koop' },
            ],
            filter: this.filter,
            field: 'plotType',
            defaultSelected: [],
        };

        return h(Vue.component('grid-filter-multi'), { props });
    }

    private renderStatusFilter(h, _, row) {
        const props = {
            options: this.statusses,
            filter: this.filter,
            field: 'rentalState',
            defaultSelected: [],
        };

        return h(Vue.component('grid-filter-multi'), { props });
    }
}
