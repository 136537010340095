import BasePage from '../../models/BasePage';
import Component from 'vue-class-component';
import { homeOwnerService, loginService, meterReadingService, sitesHelper, userService } from '@/main';
import Vue from 'vue';
import { BModal } from 'bootstrap-vue';
import to from 'await-to-js';
import HomeOwner from '../../models/HomeOwner';
import { $router } from '@/router';
import { sitesModule } from '@/store/modules/site';
import { orderBy } from '@progress/kendo-data-query';

@Component
export default class HomeOwnersPage extends BasePage {
    public homeOwners: HomeOwner[] = [];
    public allHomeOwners: HomeOwner[] = [];
    public homeOwner: HomeOwner = new HomeOwner();
    public homeOwnersGridData: any = [];
    public homeOwnersGridColumns = [
        { field: 'lastName', title: 'Naam', cell: this.renderName },
        { field: 'emailAddress', title: 'E-mail', cell: this.renderHomeOwnerLink },
        { field: 'phoneNumber', title: 'Telefoon' },
        { field: 'homeOwnerType', title: 'Type', cell: this.renderType, width: 200 },
        { field: 'places.length', cell: this.renderAccommodationSelect, title: 'Accommodaties', filterable: false },
        { title: 'Acties', cell: this.renderActions, filterable: false, width: 150, sortable: false },
    ];

    public showDeleted: boolean;
    public skip: number = 0;
    public take: number = 25;
    public updatedGrid: number = 200;

    public sort: any[] = [{ field: 'lastName', dir: 'asc' }];

    public $refs!: {
        newHomeOwnerModal: BModal;
    };

    public async mounted() {
        await Promise.all([this.initSite(), this.getHomeOwners()]);
        this.isLoading = false;
    }

    public async reloadHomeOwners() {
        this.showDeleted = !this.showDeleted;
        this.showPending('Huiseigenaren ophalen..');
        await this.getHomeOwners();

        this.clearNotifications();
    }

    public renderHomeOwnerLink(h: any, a, row) {
        const homeOwner = row.dataItem;
        const route = $router.resolve({
            name: 'home-owner',
            params: { siteId: this.siteId.toString(), siteKey: this.siteKey, homeOwnerId: row.dataItem.homeOwnerId },
        });

        const title = homeOwner.emailAddress;
        const props = { text: title, url: route.href };

        return h(Vue.component('grid-router-link'), { props });
    }

    public createHomeOwnerSideAction() {
        this.$sideActions.push('create-or-edit-home-owner-side-action', {}, (result) => {
            this.getHomeOwners();
        });
    }

    public uploadHomeOwnersSideAction() {
        this.$sideActions.push('upload-home-owners-side-action', {}, (result) => {
            this.getHomeOwners();
        });
    }

    public editHomeOwner(item: HomeOwner) {
        const params = {
            site: this.siteKey,
            homeOwnerId: item.homeOwnerId.toString(),
        };

        $router.push({ name: 'home-owner', params });
    }

    public update(dataItems) {
        this.homeOwners = dataItems;

        this.updatedGrid++;
    }

    public async sendSetPasswordMail(item: HomeOwner) {
        this.showPending('Wachtwoord herstel mail versturen...');
        const success = await homeOwnerService.resetPassword(item.homeOwnerId);
        if (!success) {
            return this.clearAndShowError('Wachtwoord herstellen mail versturen mislukt');
        }

        return this.clearAndShowSuccess('Wachtwoord herstellen mail versturen gelukt!');
    }

    public getHomeOwnersData() {
        if (!this.homeOwners) {
            return [];
        }

        const ordered = orderBy(this.homeOwners, this.sort);
        return ordered.slice(this.skip, this.take + this.skip);
    }

    public get total() {
        return this.homeOwners ? this.homeOwners.length : 0;
    }

    public async deleteHomeOwner(homeOwner: HomeOwner) {
        // to do implement
        this.showPending('Huiseigenaar archiveren...');
        const [err, response] = await to(homeOwnerService.deleteHomeOwner(homeOwner.homeOwnerId));
        if (err) {
            this.clearAndShowError('Mislukt om de huiseigenaar te verwijderen');
            return;
        }

        this.clearAndShowSuccess('Huiseigenaar verwijderd.');
        this.homeOwners = this.homeOwners.filter((x) => x.homeOwnerId !== homeOwner.homeOwnerId);
    }

    public pageChangeHandler(event) {
        this.skip = event.page.skip;
        this.take = event.page.take;
    }

    private renderActions(h: any, a, row) {
        const actions = [
            { title: 'Bewerk', function: this.editHomeOwner },
            { title: `Stuur 'herstel wachtwoord'-mail`, function: this.sendSetPasswordMail },
        ];
        if (!row.dataItem.places || row.dataItem.places.length === 0) {
            actions.push({ title: 'Verwijder', function: this.deleteHomeOwner });
        }

        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }

    private async getHomeOwners() {
        const [err, response] = await to(homeOwnerService.getHomeOwners(this.showDeleted));
        if (err) {
            return this.showError('Mislukt om huiseigenaren op te halen');
        }

        return (this.allHomeOwners = this.homeOwners = response.data);
    }

    private renderName(h: any, a, row) {
        const homeOwner = row.dataItem;
        const route = $router.resolve({
            name: 'home-owner',
            params: { siteId: this.siteId.toString(), siteKey: this.siteKey, homeOwnerId: row.dataItem.homeOwnerId },
        });

        let parts = [row.dataItem.firstName, row.dataItem.insertion, row.dataItem.lastName];
        parts = parts.filter((value) => !!value);
        const name = parts.join(' ');

        const props = { text: name, url: route.href };

        return h(Vue.component('grid-router-link'), { props });
    }

    private renderType(h: any, a, row) {
        return h('td', row.dataItem.homeOwnerType === 'Private' ? 'Particulier' : 'Bedrijf');
    }

    private renderAccommodationSelect(h: any, a, row) {
        const self = this;
        const accommodations = row.dataItem.places;
        if (accommodations.length === 0) {
            return h('td', `geen accommodatie`);
        }

        if (accommodations.length === 1) {
            const route = $router.resolve({
                name: 'home-owner-acco',
                params: {
                    siteId: accommodations[0].siteId.toString(),
                    siteKey: sitesModule.sites.find((x) => x.siteId === accommodations[0].siteId).siteKey,
                    accoKey: accommodations[0].placeId,
                },
            });

            const title = `${accommodations[0].lotNumber} - ${accommodations[0].placeName} - ${accommodations[0].siteName}`;
            const propsOneAco = { text: title, url: route.href };

            return h(Vue.component('grid-router-link'), { props: propsOneAco });
        }

        accommodations.forEach((element) => {
            element.name = `${element.lotNumber} - ${element.placeName} - ${element.siteName}`;
        });

        const props = {
            text: `${accommodations.length} accommodaties`,
            items: accommodations,
            route: 'acco',
            textField: 'name',
            valueField: 'placeId',
            callback(item) {
                self.$router.push({
                    name: 'home-owner-acco',
                    params: {
                        siteId: item.siteId.toString(),
                        siteKey: sitesModule.sites.find((x) => x.siteId === item.siteId).siteKey,
                        accoKey: item.placeId,
                    },
                });
            },
        };

        return h(Vue.component('grid-select'), { props });
    }
}
