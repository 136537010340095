import moment from 'moment';
export default class MeterReading {
    public placeId: number;

    public items: MeterReadingItem[] = [];

    public mutationDate: string = moment().format('YYYY-MM-DD');
    public mutationDateObject: Date = moment().toDate();
}

// tslint:disable-next-line: max-classes-per-file
export class MeterReadingItem {
    public ean: string;
    public latestMutationDate: string;
    public meterType: string;
    public value: number;
    public previousValue: number;
    public isNew: boolean;
    public deposit: number;
    public numberOfDeposits: number = 12;
    public depositAmount: number = 0;

    /**
     *
     */
    constructor(obj?: Partial<MeterReadingItem>) {
        if (obj) {
            Object.assign(this, obj);

            this.previousValue = this.value;
        }
    }
}
