import BasePage from '../../models/BasePage';
import Component from 'vue-class-component';
import { $router, dictionaryHelper, homeOwnerService, invoiceService, siteService } from '@/main';
import to from 'await-to-js';
import HomeOwner from '../../models/HomeOwner';
import Vue from 'vue';
import Place from '@/models/Place';
import AnnualInvoice from '@/models/AnnualInvoice';
import { Invoice } from '@/models/Invoice';
import { sitesModule } from '@/store/modules/site';
import moment from 'moment';

@Component
export default class BookerEditPage extends BasePage {
    public homeOwner: HomeOwner = new HomeOwner({ address: {} });

    public skip: number = 0;
    public invoicesSkip: number = 0;
    public take: number = 25;
    public invoicesTake: number = 25;

    public accoColumns = [
        { field: 'name', title: 'Accommodatie', editable: false, cell: this.renderAccomodationName },
        { field: 'lotNumber', title: 'Plek', editable: false },
        { field: 'siteName', title: 'Park', editable: false },
        { field: 'home-owner', title: 'Huiseigenaar', cell: this.renderHomeOWner },
        { cell: this.renderActions, title: 'Actions', width: 150 },
    ];

    public invoiceColumns = [
        { field: 'invoiceNumber', cell: this.renderInvoiceLink, title: 'Factuurnummer' },
        { cell: this.renderLink, title: 'Boeking' },
        { field: 'invoiceDate', title: 'Factuurdatum', cell: this.renderInvoiceDate },
        { field: 'dueDate', title: 'Verloopdatum', cell: this.renderDueDate },
        // { field: 'mainBooker', title: 'Hoofdboeker', cell: this.renderMaindbookerLink },
        { field: 'totalAmount', title: 'Bedrag', cell: this.renderValuta },
        { title: 'Openstaand bedrag', cell: this.renderOpenstaandValuta },
        { title: 'Status', cell: this.renderStatus },
        { title: 'Is crediteer factuur?', cell: this.renderCredit, filterable: false, width: 150 },
        // { title: 'Acties', cell: this.renderActions, filterable: false, width: 150 },
    ];

    public invoices = [];

    public async mounted() {
        this.initSite();

        await this.loadHomeOwner();
        await this.loadInvoices();

        this.editMode = true;
        this.isLoading = false;
    }

    public async loadHomeOwner() {
        const homeOwnerId = this.$route.params.homeOwnerId;
        const [err, response] = await to(homeOwnerService.getHomeOwner(parseInt(homeOwnerId, 10)));
        if (err) {
            return this.showError('Eigenaar ophalen mislukt');
        }

        this.homeOwner = new HomeOwner(response.data);
    }

    public async loadInvoices() {
        const [err, response] = await to(
            homeOwnerService.getInvoices(this.homeOwner.homeOwnerId),
        );
        if (err) {
            return this.showError('Jaarfacturen ophalen mislukt');
        }

        this.invoices = response.data.map((x) => new Invoice(x));
    }

    public getAccoData() {
        return this.homeOwner.places ? this.homeOwner.places.slice(this.skip, this.take + this.skip) : [];
    }

    public pageChangeHandler(event) {
        this.skip = event.page.skip;
        this.take = event.page.take;
    }

    public pageChangeHandlerInvoices(event) {
        this.invoicesSkip = event.page.skip;
        this.invoicesTake = event.page.take;
    }

    public get total() {
        return this.homeOwner.places ? this.homeOwner.places.length : 0;
    }

    public get invoicesTotal() {
        return this.invoices ? this.invoices.length : 0;
    }

    public getInvoices() {
        return this.invoices.slice(this.invoicesSkip, this.invoicesTake + this.invoicesSkip);
    }

    public getFullName() {
        return `${this.homeOwner.firstName}${this.homeOwner.insertion ? ' ' + this.homeOwner.insertion : ''} ${this.homeOwner.lastName}`;
    }

    public async save() {
        this.showPending('Huiseigenaar opslaan..');
        const [err, response] = await to(homeOwnerService.updateHomeOwner(this.homeOwner));
        if (err) {
            this.clearAndShowError('Mislukt om huiseigenaar op te slaan');
            return;
        }

        this.clearAndShowSuccess('Huiseigenaar opgeslagen');
        this.$emit('created', this.homeOwner);
    }

    public renderHomeOWner(h, tdElement, row) {
        if (!this.homeOwner) {
            return h('td', ['-']);
        }

        return h('td', [this.homeOwner.name()]);
    }

    public renderAccomodationName(h, a, row) {
        const item = row.dataItem;
        const route = $router.resolve({
            name: 'home-owner-acco',
            params: {
                siteId: item.siteId.toString(),
                siteKey: sitesModule.sites.find((x) => x.siteId === item.siteId).siteKey,
                accoKey: item.placeId,
            },
        });
        const props = { text: item.placeName, url: route.href };

        return h(Vue.component('grid-router-link'), { props });
    }

    public renderActions(h, tdElement, row) {
        const actions = [{ title: 'Ontkoppel huiseigenaar', function: this.decoupleHomeOwner }];
        const props = { actions, item: row.dataItem };
        return h(Vue.component('grid-actions'), { props });
    }

    public async decoupleHomeOwner(item: Place) {
        this.showPending('Eigenaar ontkoppelen...');

        const model = {
            homeOwnerId: this.homeOwner.homeOwnerId,
            placeId: item.placeId,
            desiredROI: item.desiredROI,
            commissionRate: item.commissionRate,
            propertyValue: item.propertyValue,
            rentCosts: item.rentCosts,
            serviceCosts: item.serviceCosts,
        } as Place;

        const [err, response] = await to(siteService.updateHomeOwnerPlace(model));
        if (err) {
            return this.clearAndShowError('Mislukt om de eigenaar te ontkoppelen', err);
        }

        this.homeOwner.places = this.homeOwner.places.filter((y) => y.placeId !== item.placeId);
        this.clearAndShowSuccess('Eigenaar succesvol ontkoppeld.');
    }

    public renderOpenstaandValuta(h: any, a, row) {
        return h('td', kendo.toString(row.dataItem.totalAmount - row.dataItem.amountPaid, 'c'));
    }

    public renderValuta(h: any, a, row) {
        return h('td', kendo.toString(row.dataItem.totalAmount, 'c'));
    }

    public renderCredit(h, a, row) {
        return h('td', [row.dataItem.isCreditInvoice ? 'Ja' : 'Nee']);
    }

    public renderStatus(h: any, a, row) {
        return h('td', dictionaryHelper.get('InvoiceStates')[row.dataItem.status]);
    }

    public renderLink(h: any, a, row) {
        if (!row.dataItem.bookingId) {
            return h('td', 'Niet gekoppeld');
        }

        const route = $router.resolve({
            name: 'booking',
            params: { siteKey: this.siteKey, bookingKey: row.dataItem.bookingId },
        });

        const props = { text: 'bk-' + row.dataItem.bookingId, url: route.href };

        return h(Vue.component('grid-router-link'), { props });
    }

    public renderInvoiceLink(h: any, a, row) {
        const route = $router.resolve({
            name: row.dataItem.status === 1 ? 'invoice-create' : 'invoice',
            params: { siteKey: this.siteKey, invoiceId: row.dataItem.invoiceId },
        });

        const props = { text: row.dataItem.invoiceNumber, url: route.href };
        return h(Vue.component('grid-router-link'), { props });
    }

    public renderInvoiceDate(h: any, a, row) {
        return h('td', moment(row.dataItem.invoiceDate, 'YYYY-MM-DD').format('DD-MM-YYYY'));
    }

    public renderDueDate(h: any, a, row) {
        if (row.dataItem.dueDate) {
            return h('td', moment(row.dataItem.dueDate).format('DD-MM-YYYY'));
        }

        return h('td', '-');
    }
}
